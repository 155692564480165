<template>
  <div class="readmore" :class="{ 'mobile-show p-3': mobileShow }">
    <p v-html="fixedText" v-if="fixedText" class="p-0 m-0"></p>
    <VueReadMoreSmooth :text="$t('product-page.read-more')" :text-less="$t('product-page.read-less')" :lines="lines">
      <p v-html="message"></p>
    </VueReadMoreSmooth>
  </div>
</template>
<script>
import VueReadMoreSmooth from 'vue-read-more-smooth';

export default {
  components: { VueReadMoreSmooth },
  props: {
    message: {
      type: String,
      default: '',
    },
    mobileShow: {
      type: Boolean,
      default: false,
    },
    fixedText: {
      type: String,
      default: '',
    },
    line: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    lines() {
      // eslint-disable-next-line no-nested-ternary
      return this.line ? this.line : this.fixedText ? 1 : 3;
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style>
.text-overflow-content p, .text-overflow-content p span {
  background: none !important;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .readmore {
    display: none;
  }
  .readmore.mobile-show {
    display: block;
    font-size: 12px;
  }
}
</style>
